var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      class: [
        _vm.isFixed ? "is-fixed" : "",
        _vm.whiteBg ? "has-bg" : "",
        _vm.scrollY > 10 ? "is-scrolled" : ""
      ],
      style: _vm.myStyle
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }