<template>

    <google-map :zoom="14" :center="{lat:51.0308025, lng:4.0999051}" :options="{styles: mapstyles, gestureHandling: 'cooperative', mapTypeControl: false, fullscreenControl: false}">

        <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position"></gmap-marker>

    </google-map>

</template>

<script>

    import Vue from 'vue';
    import * as VueGoogleMaps from 'vue2-google-maps';

    Vue.use(VueGoogleMaps, {

        load: {
            key: 'AIzaSyAAZo1INSC1Ho6jYcg5AcLQKDG24Z_0ByY',
            language: 'nl-BE',
            region: 'be',
            libraries: 'places'
        }
    });

    export default {

        components: {
            'google-map': VueGoogleMaps.Map,
            'gmap-marker' : VueGoogleMaps.Marker
        },

        data() {

            return {
                place: '',
                infoContent: '',
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                /*
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                */
                markers: [
                    {
                        position: {
                            lat: 51.0308025,
                            lng: 4.0999051
                        },
                        infoText: 'Koningin Astridlaan 7, 9200 Dendermonde',
                        title: 'Yogaschool Joos'
                    }
                ]
            }
        }
    }

</script>

<style>

    .vue-map-container {
        width: 100%;
        height: 500px;
    }

</style>
