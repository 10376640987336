// Vendors
import $ from "jquery";
import Vue from 'vue';

// Components
//import navmobile from './components/navmobile.vue';
import { Slide } from 'vue-burger-menu';
//import alert from './components/alert.vue';
//import tabs from './components/tabs.vue';
//import tab from './components/tab.vue';
import stickyTop from './components/stickyTop.vue';
//import codesnippet from './components/codesnippet.vue';
//import magnificPopupModal from './components/MagnificPopupmodal';
//import frminput from './components/frminput.vue';

// Vue vendor modules
import VueScrollTo from "vue-scrollto";
//import VueScrollactive from "vue-scrollactive";
//import VueScrollReveal from "vue-scroll-reveal";
//import VueSlider from "vue-slider-component";

//import slick from 'vue-slick';
import googlemaps from './components/gmap.vue';

// Other vendor modules
//import RoyalSlider from './classes/royalslider';


import MagnificPopupModal from './components/MagnificPopupmodal';


class App {

    constructor() {

        // jQuery enkel nodig voor Slick Slider / Royal Slider
        window.jQuery = $;
        window.$ = $;

        //this.royalslider = new RoyalSlider();

        Vue.use(VueScrollTo, {
            container: "body",
            duration: 500,
            easing: "ease",
            offset: 0,
            force: true,
            cancelable: true,
            onStart: function(element) {},
            onDone: function (element) {},
            onCancel: function (element) {},
            x: false,
            y: true
        });

        //Vue.use(VueScrollactive);

        /*
        Vue.use(VueScrollReveal, {
            class: 'v-scroll-reveal',
            duration: 800,
            scale: 1,
            distance: '10px',
            mobile: false
        });
        */
    }

    init() {

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            components: {
                // Components
                Slide,
                MagnificPopupModal,
                //'navmobile': navmobile,
                //'alert': alert,
                //'tabs': tabs,
                //'tab': tab,
                'sticky-top': stickyTop,
                //'codesnippet': codesnippet,
                //'magnific-popup-modal': magnificPopupModal,
                //'frminput': frminput,

                // Vendors
                //'slick': slick,
                //VueSlider
                'googlemaps': googlemaps,
            },
            data() {
                return {
                    //value: 5,
                    //marks: val => val % 1 === 0,
                    /*
                    slickOptions: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        lazyLoad: 'ondemand',
                        arrows: true,
                        dots: true,
                        centerMode: true,
                        focusOnSelect: false,
                        infinite: true,
                        accessibility: true,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 639,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    arrows: false,
                                }
                            }
                        ]
                    }
                    */
                }
            },

            methods: {

                openModal (modal) {
                    this.$refs[modal].open()
                }
            }
        });
        //this.royalslider.init();
    }
}

$(() => {

    const app = new App();

    app.init();

});
