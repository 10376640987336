var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "google-map",
    {
      attrs: {
        zoom: 14,
        center: { lat: 51.0308025, lng: 4.0999051 },
        options: {
          styles: _vm.mapstyles,
          gestureHandling: "cooperative",
          mapTypeControl: false,
          fullscreenControl: false
        }
      }
    },
    _vm._l(_vm.markers, function(m, i) {
      return _c("gmap-marker", { key: i, attrs: { position: m.position } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }